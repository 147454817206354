import { graphql } from "gatsby"
import React from "react"
import TypeformEmbed from "../../organisms/form/TypeformEmbed"
import DatoStructuredTextBlock from "../DatoStructuredTextBlock"

type DatoTypeformProps = Queries.DatoTypeformFragment

const DatoTypeform = (props: DatoTypeformProps) => {
  // console.debug("Renreding DatoTypeform", props)

  const { formId, lazy, sandbox, inlineOnMobile } = props

  if (!formId) {
    return null
  }
  const disclaimer = props.disclaimer ? <DatoStructuredTextBlock data={props.disclaimer} shiftHeadings={1} /> : null

  return (
    <section className="layout-container">
      <TypeformEmbed
        formId={formId}
        lazy={lazy === null ? false : lazy}
        sandbox={sandbox === null ? false : sandbox}
        inlineOnMobile={inlineOnMobile === null ? false : inlineOnMobile}
        disclaimer={disclaimer}
      />
    </section>
  )
}

export default DatoTypeform

export const query = graphql`
  fragment DatoTypeform on DatoCmsTypeform {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    formId
    lazy
    inlineOnMobile
    sandbox
    disclaimer {
      value
      links {
        __typename
        ... on DatoCmsRouteLink {
          ...DatoRouteLink
        }
        ... on DatoCmsExternalUrl {
          ...DatoExternalUrl
        }
      }
    }
  }
`
