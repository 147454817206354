import { Widget } from "@typeform/embed-react"
import React from "react"
import * as s from "./TypeformEmbed.module.scss"

type TypeformEmbedProps = {
  formId: string
  /* enable lazy loading (for widget only), typeform starts loading when user scrolls to it. Default `true` */
  lazy?: boolean
  /** Enable [sandbox](https://www.typeform.com/help/a/test-your-typeforms-without-creating-an-entry-for-advanced-users-360029295952/) mode (disables submissions and tracking) */
  sandbox?: boolean
  /** Removes placeholder welcome screen in mobile and makes form show inline instead of fullscreen */
  inlineOnMobile?: boolean
  disclaimer?: React.ReactNode
}

const TypeformEmbed = (props: TypeformEmbedProps) => {
  // console.debug("Renreding TypeformEmbed", props)
  const { lazy = false, sandbox = false, inlineOnMobile = false } = props

  return (
    <div>
      <Widget id={props.formId} lazy={lazy} enableSandbox={sandbox} inlineOnMobile={inlineOnMobile} autoResize={true} />
      <div className={s.disclaimer}>{props.disclaimer}</div>
    </div>
  )
}

export default TypeformEmbed
